




















































import {
  Component,
  Inject, Prop,
  Vue,
} from 'vue-property-decorator';
import { AppStore } from '@/store/types/AppStore';
import { Polygon } from '@/models/entities/Polygon';
import { SidenavItem } from '@/types/SidenavItem';
import { RouteRecordPublic } from 'vue-router';

const polygonRoutes = [
  'polygon-home',
  'polygon-users',
  'polygon-payload',
  'polygon-schedule',
  'polygon-cameras',
  'polygon-stat',
];

@Component({})
export default class PolygonsSideNav extends Vue {
  @Inject() appStore!: AppStore;
  @Inject() routesGroupedByName!: Record<string, RouteRecordPublic>;

  @Prop({ required: true })

  polygonRoute!: RouteRecordPublic;
  currentPolygonId: number = 0;

  mounted() {
    if (this.$route.name && polygonRoutes.includes(this.$route.name)) {
      const id = parseInt(this.$route.params.id, 10);

      if (!id) {
        return;
      }

      this.currentPolygonId = id;
    }
  }

  selectedItem: string = '';

  get childrenPolygonRoutes() {
    return polygonRoutes.map((routeName) => this.routesGroupedByName[routeName]);
  }

  get currentPolygon(): Polygon | null {
    if (!this.currentPolygonId) {
      return null;
    }
    return this.appStore.getters.getPolygonById(this.currentPolygonId) || null;
  }

  get polygons() {
    return this.appStore.getters.availablePolygons;
  }

  sidenavPolygonItems(polygon: Polygon): SidenavItem[] {
    const id = String(polygon.polygonId);
    return this.childrenPolygonRoutes.map((r) => {
      return {
        title: r.meta.title,
        icon: r.meta.icon,
        route: {
          name: r.name,
          params: { id },
        },
      };
    });
    // return [
    //   {
    //     title: 'Полигон',
    //     icon: 'mdi-grid',
    //     route: {
    //       name: 'polygon-home',
    //       params: { id },
    //     },
    //   },
    //   {
    //     title: 'Пользователи',
    //     icon: 'mdi-account-group',
    //     route: {
    //       name: 'polygon-users',
    //       params: { id },
    //     },
    //   },
    //   {
    //     title: 'Управление ПН',
    //     icon: 'mdi-leak',
    //     route: {
    //       name: 'polygon-payload',
    //       params: { id },
    //     },
    //   },
    //   {
    //     title: 'Расписание',
    //     icon: 'mdi-calendar-clock',
    //     route: {
    //       name: 'polygon-schedule',
    //       params: { id },
    //     },
    //   },
    //   {
    //     title: 'Камеры',
    //     icon: 'mdi-cctv',
    //     route: {
    //       name: 'polygon-cameras',
    //       params: { id },
    //     },
    //   },
    //   {
    //     title: 'Статистика',
    //     icon: 'mdi-chart-bar',
    //     route: {
    //       name: 'polygon-stat',
    //       params: { id },
    //     },
    //   },
    // ];
  }

  selectPolygon() {
    if (!this.currentPolygonId) {
      return;
    }

    if (this.$route.name && polygonRoutes.includes(this.$route.name)) {
      this.$router.push({ name: this.$route.name, params: { id: String(this.currentPolygonId) } });
    } else {
      this.$router.push({ name: 'polygon-home', params: { id: String(this.currentPolygonId) } });
    }
  }
}
