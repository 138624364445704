import { AxiosError } from 'axios';
import VueRouter from 'vue-router';

export function createNotAuthInterceptor(router: VueRouter) {
  return function (error: AxiosError) {
    if (error?.response?.status === 401) {
      window.localStorage.setItem('voltbro.auth.last-url', router.currentRoute.fullPath);
      window.location.href = '/login';
    }
    return Promise.reject(error);
  };
}
