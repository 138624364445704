





















import { Component, Inject, Vue } from 'vue-property-decorator';
import { AppStore } from '@/store/types/AppStore';
import { NotificationItem } from '@/types/NotificationItem';

@Component({})
export default class NotificationList extends Vue {
  @Inject() appStore!: AppStore;

  get notifications() {
    return this.appStore.getters.notifications;
  }

  handleInput(val: boolean, notification: NotificationItem) {
    if (!val) {
      this.handleClose(notification);
    }
  }

  handleClose(notification: NotificationItem) {
    this.appStore.actions.removeNotification(notification);
  }

  calcBottom(index: number): number {
    let offset = 0;
    for (let i = 0; i < index; i++) {
      const e = (this.$refs[`n_${i}`] as Vue[])?.[0] as Vue;
      offset += e?.$el?.querySelector('.v-snack__wrapper')?.clientHeight || 0;
      console.log(e, offset);
    }
    return offset;
  }
}
