import Wampy, {
  WampyInstance,
  Payload,
  PublishCallbacksHash,
  EventCallback,
  SubscribeCallbacksHash, DataArgs,
} from 'wampy';
import { CellStatus } from '@/types/Statuses';
import { PayloadStateDto } from '@/types/Wamp';
import { Cell } from '@/models/entities/Cell';

export class WampService {
  private wampy: WampyInstance;

  constructor(realm: string, debug: boolean = false) {
    this.wampy = new Wampy(process.env.VUE_APP_WAMP_URL, {
      realm,
      debug,
    });
  }

  subscribe(topic: string, callbacks: EventCallback | SubscribeCallbacksHash) {
    this.wampy.subscribe(topic, callbacks);
  }

  publish(topic: string, payload: Payload, callbacks?: PublishCallbacksHash) {
    this.wampy.publish(topic, payload, callbacks);
  }

  subscribeToCellsUpdates(
    cells: Cell[],
    handlers: { onReceiveCellUpdate: (event: DataArgs) => void },
    payloadState: Record<string, PayloadStateDto['payload']>,
  ): void {
    cells.filter((c) => c.status === CellStatus.active)
      .forEach((cell) => {
        cell.cellFeatures.forEach((cf) => {
          // example: polygon1.cell1.heater3.status
          const uri = `polygon${cell.polygonId}.cell${cell.sequentialNumber}.${cf.cellFeatureId}.status`;
          const uriState = `polygon${cell.polygonId}.cell${cell.sequentialNumber}.${cf.cellFeatureId}.state`;

          if (payloadState[uri]) {
            return;
          }

          // Подпишемся на будущие обновления
          this.wampy.subscribe(uri,
            {
              onEvent: handlers.onReceiveCellUpdate,
              onError: (e) => console.error('wamp subscribe error', e),
            });

          // и запросим сразу текущее состояние
          this.wampy.publish(uriState);
        });
      });
  }
}
