import Janus, {
  JSEP,
  Message,
  PluginHandle,
  MessageType,
} from 'janus';
import {
  ListCamerasMessage,
  StartStreamMessage,
  StreamDto,
  WatchStreamMessage,
} from '@/types/JanusStreamTypes';
import { promiseTimer } from '@/services/promiseTimer';

export class JanusServerService {
  private _initInitialized: boolean = false;
  private _inProcessOfInitialization: boolean = false;
  private janus!: Janus;
  private streaming: PluginHandle | null = null;
  private listOfCameras: StreamDto[] = [];
  private streamId: number = 0;
  private streamStatus: MessageType | undefined;
  private videoElement: HTMLMediaElement | null = null;

  constructor(
    public readonly janusServerUrl: string,
    public readonly debug: boolean,
  ) {
  }

  get isInitialized() {
    return this._initInitialized;
  }

  async initialize(): Promise<void> {
    if (this._inProcessOfInitialization) {
      return promiseTimer(2000)
        .then(() => {
          return this.initialize();
        });
    }
    this._inProcessOfInitialization = true;
    if (!this.isInitialized) {
      await this.initializeJanusLibrary();
      await this.startJanusSession();
      this._initInitialized = true;
    }
    this._inProcessOfInitialization = false;
    return undefined;
  }

  private async initializeJanusLibrary() {
    const { default: adapter } = await import('webrtc-adapter');
    return new Promise((resolve) => {
      Janus.init({
        debug: this.debug,
        dependencies: Janus.useDefaultDependencies({ adapter }),
        callback() {
          resolve();
        },
      });
    });
  }

  private startJanusSession() {
    return new Promise((resolve, reject) => {
      this.janus = new Janus({
        server: this.janusServerUrl,
        success: async () => {
          await this.onJanusSessionSuccess();
          resolve();
        },
        error: (cause) => {
          this.onJanusSessionError(cause);
          reject(cause);
          console.error(cause);
        },
      });
    });
  }

  private onJanusSessionSuccess(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.janus.attach({
        plugin: 'janus.plugin.streaming',
        success: async (pluginHandle: PluginHandle) => {
          await this.onStreamingPluginAttachSuccess(pluginHandle);
          resolve();
        },
        onremotestream: this.onRemoteStream.bind(this),
        onmessage: this.onStreamMessage.bind(this),
        error: (e) => {
          reject(e);
          console.error('__ Plugin attach error:', e);
        },
      });
    });
  }

  private onStreamMessage(message: Message, jsep?: JSEP): void {
    if (!this.streaming) {
      return;
    }

    const result = message.result;
    this.streamStatus = result?.status;

    if (message.error) {
      console.error('__MESSAGE ERROR', message.error);
    }

    if (jsep) {
      this.streaming.createAnswer({
        jsep,
        media: {
          audioSend: false,
          audio: false,
          videoSend: false,
          data: true,
        },
        success: (rJsep: JSEP) => {
          if (!this.streaming) {
            return;
          }
          const messageStart: StartStreamMessage = {
            message: {
              request: 'start',
            },
            jsep: rJsep,
          };
          this.streaming.send(messageStart);
        },
        error(error: unknown) {
          Janus.error('WebRTC error:', error);
        },
      });
    }
  }

  private onRemoteStream(stream: MediaStream) {
    if (this.videoElement) {
      Janus.attachMediaStream(this.videoElement, stream);
    }
  }

  private onJanusSessionError(cause: unknown) {
    setTimeout(() => this.startJanusSession(), 10000);
    console.error('__ Janus session error', cause);
  }

  private async onStreamingPluginAttachSuccess(pluginHandle: PluginHandle) {
    this.streaming = pluginHandle;
    this.listOfCameras = await this.fetchCameraList();

    if (this.streamId && this.listOfCameras.findIndex((s) => s.id === this.streamId) !== -1) {
      this.startWatchStream(this.streamId);
    }
  }

  private startWatchStream(streamId: number) {
    if (!this.streaming) {
      return;
    }
    this.streamId = streamId;
    const message: WatchStreamMessage = {
      message: {
        request: 'watch',
        id: streamId,
      },
    };
    this.streaming.send(message);
  }

  private fetchCameraList(): Promise<StreamDto[]> {
    return new Promise<StreamDto[]>((resolve) => {
      if (!this.streaming) {
        resolve([]);
        return;
      }

      const message: ListCamerasMessage = {
        message: {
          request: 'list',
        },
        success: (r) => {
          const sortedList = (r.list || []).sort(
            (s1, s2) => (s1.id > s2.id ? 1 : -1),
          );
          resolve(sortedList);
        },
      };
      this.streaming.send(message);
    });
  }

  private getStreamTitle() {
    const stream = this.listOfCameras.find((s) => s.id === this.streamId);
    return stream?.description || '';
  }

  async stopStream(): Promise<void> {
    if (!this.streaming || !this.streamId) {
      return;
    }
    const stopBody = { request: 'stop' };
    await new Promise((resolve) => {
      if (!this.streaming) {
        resolve();
        return;
      }
      this.streaming.send({
        message: stopBody,
        success: resolve,
      });
      this.streaming.hangup();
    });
    this.streamId = 0;
  }

  async startStream(videoId: number, videoElement: HTMLVideoElement) {
    if (this.streamId && this.streamId !== videoId) {
      await this.stopStream();
    }
    this.videoElement = videoElement;
    this.videoElement.muted = true;
    this.startWatchStream(videoId);
    // this.onStreamingPluginAttachSuccess();
    // Janus.attachMediaStream(videoElement, stream);
  }
}
