import { Getters } from 'vuex-smart-module';
import { AppState } from '@/store/app/AppState';
import { User } from '@/models/entities/User';
import { Polygon } from '@/models/entities/Polygon';
import { NotificationItem } from '@/types/NotificationItem';

export class AppGetters extends Getters<AppState> {
  get name() {
    return this.state.someVal;
  }

  get availablePolygons() {
    return this.state.availablePolygons;
  }

  get cellFeatures() {
    return this.state.cellFeatures;
  }

  get availablePolygonsMappedByIriDd(): Record<string, Polygon> {
    return this.state.availablePolygons.reduce<Record<string, Polygon>>((res, p) => {
      res[p['@id']] = p;
      return res;
    }, {});
  }

  getPolygonById(id: number) {
    return this.getters.availablePolygons.find((p) => p.polygonId === id) || null;
  }

  get profile(): User | null {
    return this.state.profile;
  }

  get notifications(): NotificationItem[] {
    return this.state.notifications || [];
  }

  get payloadState() {
    return this.state.payloadState;
  }
}
