import { AppStore } from '@/store/types/AppStore';
import { NotificationItem } from '@/types/NotificationItem';
import { UUID } from '@/types/CommonTypes';

export class NotificationsService {
  constructor(private appStore: AppStore) {
  }

  async addNotification(item: NotificationItem) {
    await this.appStore.actions.addNotification(item);
  }

  async removeNotification(item: NotificationItem | UUID) {
    await this.appStore.actions.removeNotification(item);
  }
}
