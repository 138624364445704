import { Module } from 'vuex-smart-module';
import { AppState } from '@/store/app/AppState';
import { AppGetters } from '@/store/app/AppGetters';
import { AppMutations } from '@/store/app/AppMutations';
import { AppActions, createActions } from '@/store/app/AppActions';
import { ApiFacade } from '@/services/ApiFacade';
import { WampService } from '@/services/WampService';

export class AppModule extends Module<AppState, AppGetters, AppMutations, AppActions> {
  public static create(apiFacade: ApiFacade, wampService: WampService) {
    return new this({
      state: AppState,
      actions: createActions(apiFacade, wampService),
      mutations: AppMutations,
      getters: AppGetters,
      namespaced: false,
    });
  }
}
