import { Actions } from 'vuex-smart-module';
import { v4 as uuidv4 } from 'uuid';
import cloneDeep from 'lodash/cloneDeep';
import { AppState } from '@/store/app/AppState';
import { AppGetters } from '@/store/app/AppGetters';
import { AppMutations } from '@/store/app/AppMutations';
import { ApiFacade } from '@/services/ApiFacade';
import { User } from '@/models/entities/User';
import { NotificationItem } from '@/types/NotificationItem';
import { UUID } from '@/types/CommonTypes';
import { PayloadStateDto } from '@/types/Wamp';
import { WampService } from '@/services/WampService';
import { Cell } from '@/models/entities/Cell';

export function createActions(apiFacade: ApiFacade, wampService: WampService) {
  class AppActions extends Actions<AppState, AppGetters, AppMutations, AppActions> {
    async fetchAvailablePolygons() {
      try {
        const polygons = await apiFacade.fetchAllPolygons();
        this.mutations.setAvailablePolygons(polygons);
      } catch (e) {
        console.error('Fetch polygons error', e);
      }
    }

    async fetchProfile() {
      const profile = await apiFacade.fetchProfile();
      this.mutations.setProfile(profile);
    }

    async fetchCellFeatures() {
      try {
        const cellFeatures = await apiFacade.fetchCellFeatures();
        this.mutations.setCellFeatures(cellFeatures);
      } catch (e) {
        console.error('Fetch cellFeatures error', e);
      }
    }

    async uploadAvatar(data: { user: User; file: File }) {
      const avatar = await apiFacade.uploadAvatar(data.user, data.file);
      this.mutations.setAvatar(avatar);
    }

    async updateProfile(user: User) {
      const updUser = await apiFacade.updateProfile(user);
      this.mutations.setProfile(updUser);
    }

    async addNotification(_notification: NotificationItem) {
      const notification = cloneDeep(_notification);
      if (!notification.id) {
        notification.id = uuidv4();
      }
      this.mutations.addNotification(notification);
    }

    async removeNotification(notification: NotificationItem | UUID) {
      this.mutations.removeNotification(notification);
    }

    setPayloadState(payload: PayloadStateDto) {
      this.mutations.setPayloadState(payload);
    }

    subscribePolygonUpdates(cells: Cell[]) {
      wampService.subscribeToCellsUpdates(cells, {
        onReceiveCellUpdate: (event) => {
          if (!event.details.topic) {
            return;
          }
          this.actions.setPayloadState({
            topic: event.details.topic,
            payload: event.argsList[0],
          });
        },
      }, this.getters.payloadState);
    }
  }

  return AppActions;
}

export type AppActions = InstanceType<ReturnType<typeof createActions>>;
