import Vue from 'vue';
import VueRouter from 'vue-router';
import Vuex from 'vuex';
import dayjs from 'dayjs';
import ru from 'dayjs/locale/ru';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';
import RelativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import Timezone from 'dayjs/plugin/timezone';
import VueSanitize from 'vue-sanitize';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import 'tiptap-vuetify/dist/main.css';
import VueMask from 'v-mask';
import { AppModule } from '@/store/app/AppModule';
import { createStore } from 'vuex-smart-module';
import { ApiLayer } from '@/api/ApiLayer';
import { WampService } from '@/services/WampService';
import axios from 'axios';
import { ApiFacade } from '@/services/ApiFacade';
import { NotificationsService } from '@/services/NotificationService';
import { createNotAuthInterceptor } from '@/services/NotAuthAxiosInterceptor';
import { JanusServerService } from '@/services/CameraServiceJanus';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import { createRouter } from './router';

dayjs.locale(ru);
dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(RelativeTime);
dayjs.extend(utc);
dayjs.extend(Timezone);

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi',
});
Vue.use(VueSanitize);
Vue.use(VueMask);

const router = createRouter(
  process.env.VUE_APP_SITE_NAME,
  window.document,
);

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});
axiosInstance.interceptors.response.use(undefined, createNotAuthInterceptor(router));

const apiFacade = new ApiFacade(new ApiLayer(axiosInstance));
const wampService = new WampService(process.env.VUE_APP_WAMP_REALM, process.env.NODE_ENV !== 'production');

export const appModule = AppModule.create(apiFacade, wampService);
const store = createStore(appModule, {
  strict: process.env.NODE_ENV !== 'production',
});

const appStore = appModule.context(store);

const notify = new NotificationsService(appStore);
const janusService = new JanusServerService(
  process.env.VUE_APP_JANUS_SERVER_URL,
  process.env.NODE_ENV !== 'production',
);

new Vue({
  router,
  store,
  vuetify,
  provide: {
    apiFacade,
    appModule,
    appStore,
    notify,
    wampService,
    janusService,
  },
  render: (h) => h(App),
}).$mount('#app');
