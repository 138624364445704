import { Polygon } from '@/models/entities/Polygon';
import { User } from '@/models/entities/User';
import { CellFeatureDto } from '@/models/dto/CellFeatureDto';
import { NotificationItem } from '@/types/NotificationItem';
import { PayloadStateDto } from '@/types/Wamp';

export class AppState {
  someVal: string = '';
  availablePolygons: Polygon[] = [];
  cellFeatures: CellFeatureDto[] = [];
  profile: User | null = null;
  notifications: NotificationItem[] = [];
  payloadState: Record<string, PayloadStateDto['payload']> = {};
}
