






































































































































































import {
  Component,
  Inject,
  Provide,
  Vue,
} from 'vue-property-decorator';
import { RouteRecordPublic } from 'vue-router';
import { Context } from 'vuex-smart-module';
import { AppModule } from '@/store/app/AppModule';
import PolygonsSideNav from '@/views/polygons/components/navigation/PolygonsSideNav.vue';
import { SidenavItem } from '@/types/SidenavItem';
import UserAvatar from '@/views/profile/components/UserAvatar.vue';
import NotificationList from '@/components/NotificationList.vue';

interface App {
  $refs: {
    polygonsSideMenu: PolygonsSideNav;
  };
}

@Component({
  components: {
    NotificationList,
    UserAvatar,
    PolygonsSideNav,
  },
})
class App extends Vue {
  @Inject() appStore!: Context<AppModule>;
  isSidenavCompact = false;

  // isSideMenuVisible = false;

  created() {
    this.appStore.actions
      .fetchProfile()
      .then(() => {
        if (this.$route.name !== 'dashboard') {
          return;
        }
        if (this.isAdmin || this.isTeacher) {
          this.$router.push('polygons');
          return;
        }
        this.$router.push('courses');
      })
      .catch((err) => {
        console.log('Authentication error', err);
        window.location.href = '/login';
      });
    this.appStore.actions.fetchAvailablePolygons();
    this.appStore.actions.fetchCellFeatures();
  }

  get profile() {
    return this.appStore.getters.profile;
  }

  get isAdmin(): boolean {
    return !!this.appStore.getters.profile?.isAdmin;
  }

  get isTeacher(): boolean {
    return !!this.appStore.getters.profile?.isTeacher;
  }

  get hasPolygons() {
    return this.appStore.getters.availablePolygons.length > 0;
  }

  @Provide()
  get routesGroupedByName() {
    return this.$router.getRoutes()
      .reduce((res, route) => {
        if (!route.name) {
          return res;
        }
        return {
          ...res,
          [route.name]: route,
        };
      }, {} as Record<string, RouteRecordPublic>);
  }

  get availableRoutes() {
    // const routes = ['dashboard'];
    const routes = [];
    if (this.isAdmin) {
      routes.push('admin-users');
    }
    return routes;
  }

  get sidenavItems(): SidenavItem[] {
    return this.getMenuItemByRouteNames(this.availableRoutes);
  }

  get teacherMenuItems(): SidenavItem[] {
    return this.getMenuItemByRouteNames(['courses', 'documents']);
  }

  get studentMenuItems(): SidenavItem[] {
    return this.getMenuItemByRouteNames(['courses', 'documents']);
  }

  @Provide()
  setCurrentPolygonInSideMenu(polygonId: number) {
    this.$refs.polygonsSideMenu.currentPolygonId = polygonId;
  }

  getMenuItemByRouteNames(names: string[]): SidenavItem[] {
    return names
      .map<SidenavItem | undefined>((routeName) => {
        const route = this.routesGroupedByName[routeName];
        if (!route) return undefined;
        return {
          title: route.meta?.title || '',
          icon: route.meta?.icon || '',
          route: { name: routeName },
        };
      })
      .filter((r): r is SidenavItem => r !== undefined);
  }

  handleSidenavItemClick(item: SidenavItem) {
    this.$router.push(item.route);
  }

  logout() {
    window.location.href = '/api/v1/logout';
  }
}

export default App;
