import { Mutations } from 'vuex-smart-module';
import { AppState } from '@/store/app/AppState';
import { Polygon } from '@/models/entities/Polygon';
import { User } from '@/models/entities/User';
import { CellFeatureDto } from '@/models/dto/CellFeatureDto';
import { NotificationItem } from '@/types/NotificationItem';
import { UUID } from '@/types/CommonTypes';
import { PayloadStateDto } from '@/types/Wamp';

export class AppMutations extends Mutations<AppState> {
  setAvailablePolygons(polygons: Polygon[]) {
    this.state.availablePolygons = polygons;
  }

  setCellFeatures(cellFeatures: CellFeatureDto[]) {
    this.state.cellFeatures = cellFeatures.sort((a, b) => {
      if (a.cellFeatureName > b.cellFeatureName) {
        return 1;
      }
      if (a.cellFeatureName < b.cellFeatureName) {
        return -1;
      }
      return 0;
    });
  }

  setProfile(profile: User) {
    this.state.profile = profile;
  }

  setAvatar(avatarUrl: string) {
    if (this.state.profile) {
      this.state.profile.avatarUrl = avatarUrl;
    }
  }

  addNotification(notification: NotificationItem) {
    this.state.notifications.push(notification);
  }

  removeNotification(notification: NotificationItem | UUID) {
    const notificationId = typeof notification === 'string' ? notification : notification.id;
    this.state.notifications = this.state.notifications.filter((n) => n.id !== notificationId);
  }

  setPayloadState(payload: PayloadStateDto) {
    const ps = { ...this.state.payloadState };
    ps[payload.topic] = payload.payload;
    this.state.payloadState = ps;
  }
}
